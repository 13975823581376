import React from "react";

export default class ErrorBoundary extends React.Component {
  state = { errorMessage: false };
  static getDerivedStateFromError(error) {
    return {
      errorMessage: error.message,
    };
  }
  render() {
    if (this.state.errorMessage) {
      return (
        <div className="relative">
          <svg className="w-full" viewBox="0 0 2 1" />
          <div className="absolute inset-0 flex items-center justify-center p-10 text-center text-black text-opacity-60">
            <div>
              <p className="text-xl">
                {this.props.title ||
                  "Ocurrió un error al cargar este contenido"}
              </p>
              {process.env.NODE_ENV === "development" ? (
                <p className="text-sm mt-4">{this.state.errorMessage}</p>
              ) : null}
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
