import classNames from "classnames";

export default function InputLabel({ label, className, children, ...rest }) {
  return (
    <label
      className={classNames("grid cursor-pointer select-none gap-1", className)}
      {...rest}
    >
      <span className="block font-display">{label}</span>
      {children}
    </label>
  );
}
