import classNames from "classnames";
import { parse } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { DayPicker } from "react-day-picker";

import formatDate from "../lib/formatDate";
import { slidePresence } from "../lib/motionVariants";
import IconCalendar from "./icons/calendar";
import IconChevronLeft from "./icons/chevronLeft";
import IconChevronRight from "./icons/chevronRight";

export default function InputDate({
  name,
  required,
  className,
  placeholder,
  defaultValue,
  onChange = () => {},
  format = "yyyy-MM-dd",
  ...props
}) {
  const [selectedDay, setSelectedDay] = useState(() =>
    defaultValue ? new parse(defaultValue, format, new Date()) : undefined
  );
  const [visible, setVisibility] = useState(false);
  const getWeekdayShort = (weekday) => {
    return weekday.toLocaleString("es-MX", { weekday: "short" });
  };
  const getMonth = (month) => {
    return month.toLocaleString("es-MX", { month: "long" });
  };
  const wrapperRef = useRef();
  const hiddenInputRef = useRef();

  useEffect(() => {
    const handleClick = (event) => {
      // Get actual element clicked inside shadow DOM
      let [target] = event.composedPath();
      if (wrapperRef.current && !wrapperRef.current.contains(target))
        setVisibility(false);
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  // Update hidden input value when selectedDay changes
  useEffect(() => {
    hiddenInputRef.current.value = selectedDay
      ? formatDate(selectedDay, format)
      : "";
    onChange({ target: hiddenInputRef.current });
  }, [selectedDay]);

  return (
    <div ref={wrapperRef} className={classNames("relative", className)}>
      <input
        ref={hiddenInputRef}
        name={name}
        required={required}
        tabIndex={-1}
        className="pointer-events-none absolute bottom-0 opacity-0"
      />
      <button
        type="button"
        onClick={() => setVisibility(!visible)}
        className={classNames(
          "relative flex h-11 w-full appearance-none items-center gap-1 rounded-lg border-2 border-brand-purple bg-white pl-3 pr-2.5 text-left transition-colors focus:outline-none focus:ring-2 focus:ring-brand-purple/40",
          className
        )}
      >
        <span className="block flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
          {selectedDay ? (
            formatDate(selectedDay, "PPP")
          ) : (
            <span className="!text-brand-deepPurple/50">
              {placeholder || "Selecciona una fecha"}
            </span>
          )}
        </span>
        <IconCalendar className="text-brand-purple" />
      </button>
      <AnimatePresence>
        {visible && (
          <motion.div
            variants={slidePresence}
            initial="initial"
            animate="visible"
            exit="hidden"
            className="absolute left-0 top-full z-10 pt-2"
          >
            <div className="rounded-lg border-2 border-brand-purple bg-white px-4 py-5">
              <DayPicker
                mode="single"
                selected={selectedDay}
                onSelect={(day) => {
                  setVisibility(false);
                  setSelectedDay(day);
                }}
                showOutsideDays
                fixedWeeks
                formatters={{
                  formatWeekdayName: getWeekdayShort,
                  formatMonthCaption: getMonth,
                }}
                components={{
                  IconLeft,
                  IconRight,
                }}
                {...props}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

function IconLeft() {
  return <IconChevronLeft className="text-2xl" />;
}
function IconRight() {
  return <IconChevronRight className="text-2xl" />;
}
