export default function IconPlus(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="currentColor"
      {...props}
    >
      <path d="M17.394,22.822h-3.8c-.5273,.0231-1.0556,.015-1.582-.024-1.0909-.1201-1.9519-.982-2.071-2.073-.1061-1.0949,.5779-2.1116,1.632-2.426,.5747-.141,1.1643-.2118,1.756-.211,1.175-.053,2.351-.067,3.651-.1,.033-.569,.1-1.009,.079-1.444-.043-1.121-.187-2.242-.166-3.36-.0453-1.1822,.8428-2.1929,2.021-2.3,1.0933-.1767,2.1475,.4868,2.461,1.549,.1754,.7033,.2849,1.4214,.327,2.145,.1,.975,.151,1.955,.229,3.019,.4005,.0984,.8081,.1653,1.219,.2,1.188,0,2.376-.056,3.564-.081,.3957-.0221,.7925-.0141,1.187,.024,1.2436,.103,2.1945,1.1523,2.175,2.4-.0365,1.1737-.94,2.1373-2.109,2.249-.717,.094-1.45,.059-2.176,.086-1.044,.038-2.089,.08-3.239,.124-.0551,.4103-.0884,.8232-.1,1.237,.03,.989,.15,1.979,.13,2.967,.0276,1.27-.9795,2.3218-2.2495,2.3494-1.0953,.0238-2.0554-.7283-2.2945-1.7974-.1381-.7113-.2227-1.432-.253-2.156,.0115-.807-.1198-1.6097-.388-2.371" />
    </svg>
  );
}
