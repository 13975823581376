import classNames from "classnames";

export default function Intro({ title, className, children }) {
  return (
    <div
      className={classNames(
        "border-deepPurpleLight max-w-prose border-t pt-6 font-sans leading-tight sm:text-lg",
        className
      )}
    >
      {title ? <h2 className="mb-1.5 font-display text-xl">{title}</h2> : null}
      {children}
    </div>
  );
}
