export const durationIn = 0.48 * 1;
export const durationOut = 0.25 * 1;
export const delayIn = durationIn * 0.8;
export const delayOut = durationOut * 0.8;

const velocity = 1;

export const springIn = {
  type: "spring",
  bounce: 0.6,
  duration: 0.7 * velocity,
};
export const tweenIn = {
  type: "tween",
  duration: 0.2 * velocity,
};
export const springOut = {
  type: "spring",
  bounce: 0.6,
  duration: 0.3 * velocity,
};
export const tweenOut = {
  type: "tween",
  duration: 0.15 * velocity,
};

export const modalBackdrop = {
  initial: { opacity: 0 },
  visible: { opacity: 1, transition: { opacity: tweenIn } },
  hidden: {
    opacity: 0,
    transition: { opacity: tweenOut },
  },
};

export const modalContainer = {
  initial: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: {
        ...springIn,
        delay: 0.15 * velocity,
      },
      opacity: {
        ...tweenIn,
        delay: 0.15 * velocity,
      },
    },
  },
  hidden: {
    y: 0,
    opacity: 0,
    transition: {
      y: springOut,
      opacity: tweenOut,
    },
  },
};

export const slidePresence = {
  initial: { y: -16, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: {
        ...springIn,
        // duration: 0.6 * velocity,
      },
      opacity: tweenIn,
    },
  },
  hidden: {
    y: 16,
    opacity: 0,
    transition: {
      y: tweenOut,
      opacity: tweenOut,
    },
  },
};

export const fadePresence = {
  initial: { opacity: 0 },
  visible: { opacity: 1, transition: tweenIn },
  hidden: { opacity: 0, transition: tweenOut },
};

export const layoutChange = {
  transition: { layout: springOut },
};
