export default function IconMinus(props) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="currentColor"
      {...props}
    >
      <path d="M13.842,16.086h-4.375c-.3921,.0167-.7848,.004-1.175-.038-.9286-.1153-1.6431-.876-1.7-1.81-.0853-.9951,.5899-1.8958,1.569-2.093,.3296-.0621,.6647-.0896,1-.082,3.1407-.0567,6.282-.108,9.424-.154,.4477-.0152,.8957,.0214,1.335,.109,1.0385,.245,1.6817,1.2855,1.4367,2.324-.1708,.7239-.742,1.2855-1.4687,1.444-.4408,.0807-.8879,.1219-1.336,.123-1.57,.053-3.14,.091-4.71,.135v.043" />
    </svg>
  );
}
