export default function GlobalState(initalState = {}) {
  // Constructor
  this.subscribers = [];
  this.stateKey = "reservationWidgetState_PAxqTAH2rk";

  // Mutate/Read state
  this.updateState = (updater) => {
    let currentState = this.getState();
    let newState =
      typeof updater === "function" ? updater(currentState) : updater;
    let nextState = {
      ...currentState,
      ...newState,
    };
    window.localStorage.setItem(this.stateKey, JSON.stringify(nextState));
    this.subscribers.forEach((subscriber) => {
      subscriber(nextState);
    });
  };
  this.getState = () => {
    let currentState = window.localStorage.getItem(this.stateKey);
    return currentState ? JSON.parse(currentState) : initalState;
  };

  // Subscribe to state changes
  this.subscribe = (itemToSubscribe) => {
    if (this.subscribers.indexOf(itemToSubscribe) > -1) {
      // Already subsribed
      return;
    }
    this.subscribers.push(itemToSubscribe);
  };
  this.unsubscribe = (itemToUnsubscribe) => {
    this.subscribers = this.subscribers.filter(
      (subscriber) => subscriber !== itemToUnsubscribe
    );
  };
}
