import styles from "bundle-text:./app.css";
import { createRoot } from "react-dom/client";

import App from "./app";
import ErrorBoundary from "./components/errorBoundary";
import GlobalState from "./lib/globalState";

const globalState = new GlobalState({
  hidden: true,
});

function render(mountElement, externalProps = {}) {
  try {
    // Create shadow root
    let shadow = mountElement.attachShadow({ mode: "open" });
    let container = document.createElement("div");
    let styleTag = document.createElement("style");
    // Append styles and container
    styleTag.textContent = styles;
    shadow.appendChild(styleTag);
    shadow.appendChild(container);
    // Render app
    const root = createRoot(container);
    root.render(
      <ErrorBoundary>
        <App globalState={globalState} externalProps={externalProps} />
      </ErrorBoundary>
    );
  } catch (error) {
    throw new Error(`Error al insertar widget`, { cause: error });
  }
}

window["WidgetReservaciones"] = {
  state: globalState,
  render,
  show: () => {
    globalState.updateState({ hidden: false });
  },
  hide: () => {
    globalState.updateState({ hidden: true });
  },
};
