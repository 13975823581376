import classNames from "classnames";
import { forwardRef } from "react";

const InputSelect = forwardRef(
  ({ className, placeholder, children, ...rest }, ref) => {
    return (
      <select
        ref={ref}
        className={classNames(
          "select-arrow flex h-11 appearance-none items-center justify-between rounded-lg border-2 border-brand-purple bg-white px-3 transition-colors invalid:text-brand-deepPurple/50 focus:outline-none focus:ring-2 focus:ring-brand-purple/40 disabled:cursor-not-allowed disabled:text-brand-deepPurple/50 disabled:opacity-40",
          className
        )}
        {...rest}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {children}
      </select>
    );
  }
);

export default InputSelect;
