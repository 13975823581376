import classNames from "classnames";

import IconOk from "./icons/ok";

export default function StepsNavigation({ steps, setStep, current, currentError }) {
  return (
    <nav className="mb-3 pt-2">
      <div className="relative mb-4 grid grid-cols-3">        
        <ProgressBar length={steps.length} current={current} />
        {steps.map((step, index) => (
          <button
            key={index}
            tabIndex={process.env.NODE_ENV === "development" ? undefined : "-1"}
            onClick={() => {
              // Only allow navigation on development
              if (process.env.NODE_ENV === "development") setStep(index);
            }}
            className="group relative grid justify-items-center gap-1.5"
          >
            <div
              className={classNames(
                "flex h-8 w-8 items-center justify-center rounded-full transition-transform duration-300",
                current === index
                  ? "!text-brand-purple ring-2 !ring-brand-purple ring-offset-8  ring-offset-white"
                  : "",
                current > index
                  ? "bg-brand-purple text-white"
                  : "bg-white text-brand-deepPurpleLight ring-2 ring-brand-deepPurpleLight ring-offset-8 ring-offset-white"
              )}
            >
              {current > index ? (
                <IconOk className="w-5" />
              ) : (
                <step.icon className="w-7" />
              )}
            </div>
          </button>
        ))}
      </div>
      <div className="relative grid grid-cols-3">
        {steps.map((step, index) => (
          <div
            key={index}
            onClick={() => setStep(index)}
            className="group relative grid justify-items-center gap-1.5"
          >
            <div
              className={classNames(
                "origin-top font-display text-xs",
                current === index
                  ? "text-brand-purple"
                  : "text-brand-deepPurpleLight"
              )}
            >
              {step.name}
            </div>
          </div>
        ))}
      </div>
      <div className="relative grid mt-5 text-center text-lg text-red">
        {currentError}
      </div>        
    </nav>
  );
}

function ProgressBar({ length, current }) {
  let left = `${100 / (length * 2)}%`;
  let width = `${400 / (length * 2)}%`;
  let scaleX = current / (length - 1);
  // scale x can't be more than 1
  if (scaleX > 1) scaleX = 1;

  return (
    <>
      <div
        className="absolute inset-y-0 my-auto h-0.5 bg-brand-deepPurpleLight"
        style={{
          left,
          width,
        }}
      />
      <div
        className="absolute inset-y-0 my-auto h-0.5 bg-brand-purple"
        style={{
          left,
          width,
          transform: `scaleX(${scaleX})`,
          transformOrigin: "left",
        }}
      />
    </>
  );
}
