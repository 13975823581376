export default function IconChevronRight(props) {
  return (
    <svg
      width="9"
      height="12"
      viewBox="0 0 9 12"
      fill="currentColor"
      {...props}
    >
      <path d="M.3832,.5999c-.2497,.4396-.1905,.9896,.147,1.366,.126,.1387,.2639,.2662,.412,.381,.971,.815,3.373,2.291,4.26,3.062,.1938,.1571,.3451,.3603,.44,.591-.0943,.2305-.2449,.4337-.438,.591-.887,.772-3.29,2.247-4.26,3.062-.1487,.1148-.2872,.2423-.414,.381-.3375,.3764-.3967,.9264-.147,1.366,.2581,.4836,.8253,.7131,1.347,.545,.3563-.1132,.691-.2856,.99-.51,1.318-1,2.616-2.021,3.921-3.036,.16-.124,.983-.811,1.7-1.409,.2909-.2439,.4493-.611,.427-.99,.022-.3791-.1367-.7463-.428-.99-.715-.6-1.539-1.285-1.7-1.409-1.3-1.015-2.6-2.038-3.921-3.036-.299-.2244-.6337-.3968-.99-.51C1.2078-.1127,.6412,.1168,.3832,.5999" />
    </svg>
  );
}
