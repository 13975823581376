export default function IconOk(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="currentColor"
      {...props}
    >
      <path d="M13.974,27.287c.511-.643,.816-1,1.089-1.372,9.461-13.034,1.831-3.138,11.292-16.173,.3836-.5639,.8045-1.1014,1.26-1.609,1.073-1.123,2.245-1.284,3.283-.517,.9774,.7301,1.2318,2.0876,.585,3.122-.3065,.6088-.6666,1.1891-1.076,1.734-8,11.058-11.126,16.122-13.224,19.068-.04,.056-.076,.113-.116,.169-1.914,2.655-4.429,2.625-6.27-.128-.5611-.8556-1.0304-1.768-1.4-2.722-.754-1.9-1.429-3.835-2.109-5.764-.1642-.4476-.2612-.917-.288-1.393-.0258-.8461,.4859-1.6161,1.276-1.92,.8472-.3526,1.825-.1188,2.421,.579,.3576,.4988,.6594,1.0354,.9,1.6,.766,1.671,1.5,3.356,2.378,5.327" />
    </svg>
  );
}
