export default function IconCalendar(props) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 12 12"
      fill="currentColor"
      {...props}
    >
      <path d="M11.8643,3.2232c.0651-1.131-.6889-2.1483-1.796-2.4233-.2212-.0491-.4406,.0892-.49,.3087-.0068,.0301-.0101,.0608-.0099,.0917v.7641c.0059,.5806-.411,1.0808-.9867,1.1839-.645,.1006-1.25-.3369-1.3513-.9771-.0091-.0577-.0139-.1161-.0143-.1745v-.9913c0-.1502-.1226-.2719-.2739-.2719h-1.782c-.2071,0-.3749,.1666-.3749,.3721v.8589c.0059,.5806-.411,1.0808-.9867,1.1839-.645,.1006-1.25-.3369-1.3513-.9771-.0091-.0577-.0139-.1161-.0143-.1745v-.5036c.0023-.3428-.2758-.6226-.6211-.6248-.0781-.0005-.1557,.0135-.2286,.0413C.6273,1.2854-.0002,2.2028,.0005,3.2232v6.1624c-.0272,1.407,1.0922,2.5732,2.5093,2.6143h6.9801c1.4195-.0357,2.542-1.2052,2.5093-2.6143l-.135-6.1624Zm-2.3604,7.843H2.5118c-.9002-.035-1.6019-.7869-1.5681-1.6805v-3.9073c.0003-.6353,.5192-1.1501,1.1592-1.1501H10.4037c.3611,0,.6538,.2906,.6538,.649h0v4.4085h.0155c.0282,.8919-.6709,1.6408-1.5691,1.6805Z" />
      <path d="M3.9909,2.534c.1465-.1046,.2292-.2761,.2194-.455l-.063-1.5585c-.0063-.1921-.1163-.366-.2879-.455-.2705-.1424-.606-.0402-.7495,.2283-.0453,.0848-.0675,.1799-.0643,.2758l.0625,1.5466c.0145,.3038,.2744,.5385,.5805,.5241,.1091-.0051,.2143-.0421,.3023-.1063Z" />
      <path d="M7.8854,2.1163c.0166,.3067,.2805,.5421,.5896,.5256,.1512-.0081,.2926-.0765,.3922-.1897,.0827-.08,.1274-.1911,.123-.3056l-.0685-1.6949c-.0046-.1146-.0581-.2219-.147-.2952-.2225-.2136-.5772-.2077-.7923,.0131-.1042,.107-.1609,.2509-.1574,.3997l.0605,1.5471Z" />
      <path d="M9.2555,8.2112l.0025,.0025c-.2784-.0268-.5585-.0328-.8378-.0179-.2291,.0132-.414,.1905-.4354,.4173-.0263,.2757-.0324,.553-.018,.8296,.0115,.2291,.1921,.4143,.4224,.4332,.2761,.0229,.5535,.0282,.8303,.0159h.023c.2156-.0221,.3874-.1889,.4144-.4024,.0354-.2798,.0411-.5625,.017-.8435-.0095-.2287-.1886-.4149-.4184-.4346Z" />
    </svg>
  );
}
