import { forwardRef, useMemo, useRef, useState } from "react";

import { apiPost } from "../lib/api";
import useApi from "../lib/useApi";
import useAsyncFn from "../lib/useAsyncFn";
import Button from "./button";
import ButtonBack from "./buttonBack";
import ButtonCircle from "./buttonCircle";
import IconMinus from "./icons/minus";
import IconPlus from "./icons/plus";
import InputDate from "./inputDate";
import InputPlaceholder from "./inputPlaceholder";
import InputSelect from "./inputSelect";
import InputText from "./inputText";
import Intro from "./intro";

export default function ReservationGuests({
  form,
  updateForm,
  handleSubmit,
  handleChange,
  moveStep,
}) {
  
  const host = "https://admin.playtica.com.mx";
  //const host = "http://playtica-admin.localhost";

  const { visitors = [{ type: "adulto" }] } = form;
  const deleteVisitor = (index) => {
    let newVisitors = [...visitors];
    newVisitors.splice(index, 1);
    updateForm({ ...form, visitors: newVisitors });
  };
  const addVisitor = () => {
    updateForm({ ...form, visitors: [...visitors, { type: "nino" }] });
  };

  return (
    <section>
      <Intro title="Invitados" className="mb-6">
        <p>
          A continuación llena los datos de las personas que asistirán al
          parque.
        </p>
      </Intro>
      <form
        onSubmit={handleSubmit}
        onChange={handleChange}
        className="grid gap-5"
      >
        {visitors.map((visitor, index) => (
          <InputVisitor
            key={index}
            index={index}
            visitor={visitor}
            handleChange={handleChange}
            onDelete={() => deleteVisitor(index)}
            host = {host}
          />
        ))}
        <div className="text-right">
          <ButtonCircle
            type="button"
            onClick={addVisitor}
            label="Añadir invitado"
          >
            <IconPlus />
          </ButtonCircle>
        </div>
        <div className="mt-5 flex items-center justify-between gap-5">
          <ButtonBack onClick={() => moveStep(-1)} />
          <Button type="submit">Continuar</Button>
        </div>
      </form>
    </section>
  );
}

function InputVisitor({
  visitor: { type, firstName, lastName, birthday, city, email, mobilePhone },
  handleChange,
  index,
  onDelete,
  host
}) {
  const inputCityRef = useRef();
  const inputEmailRef = useRef();
  const inputPhoneRef = useRef();
  // Calculate date ranges for birthday input
  const [fromDate, toDate] = useMemo(() => {
    let from = new Date();
    let to = new Date();
    // If type is "adulto" then 130 years ago to 13 years ago
    if (type === "adulto") {
      from.setFullYear(from.getFullYear() - 130);
      to.setFullYear(to.getFullYear() - 13);
    }
    // If type is "nino" then 13 years ago to today
    else {
      from.setFullYear(from.getFullYear() - 13);
    }
    return [from, to];
  }, [type]);

  const [{ success }, findVisitor] = useAsyncFn(
    (data) =>
      apiPost(host + "/api/find_visitor", data).then(
        (visitors) => {
          let { lastName, email, mobilePhone, city } = visitors[0] || {};
          // Update input values
          if (email) inputEmailRef.current.value = email;
          if (mobilePhone) inputPhoneRef.current.value = mobilePhone;
          if (city) inputCityRef.current.value = city.id;
        }
      ),
    []
  );

  return (
    <article className="relative rounded-lg bg-[#F9F8EF] px-4 pt-5 pb-6">
      {index > 0 ? (
        <ButtonCircle
          type="button"
          onClick={onDelete}
          small
          colors="warning"
          className="absolute -top-3 -right-2"
          title="Eliminar invitado"
        >
          <IconMinus />
        </ButtonCircle>
      ) : null}
      <header className="mb-5 flex gap-4 px-1">
        <InputRadio
          label="Adulto"
          name={`visitors[${index}][type]`}
          value="adulto"
          defaultChecked={type === "adulto"}
          required
        />
        <InputRadio
          label="Niño"
          name={`visitors[${index}][type]`}
          value="nino"
          disabled={index === 0}
          defaultChecked={type === "nino"}
          required
        />
      </header>
      <div className="grid gap-5">
        <InputDate
          name={`visitors[${index}][birthday]`}
          captionLayout="dropdown"
          fromDate={fromDate}
          toDate={toDate}
          defaultMonth={toDate}
          format="yyyy-MM-dd"
          placeholder="Fecha de nacimiento"
          defaultValue={birthday}
          onChange={handleChange}
          required
        />
        <div className="grid grid-cols-2 gap-4">
          <InputText
            name={`visitors[${index}][firstName]`}
            placeholder="Nombre"
            defaultValue={firstName}
            required
          />
          <InputText
            name={`visitors[${index}][lastName]`}
            onBlur={() => {
              findVisitor({
                birthday,
                firstName,
                lastName,
              });
            }}
            placeholder="Apellido"
            defaultValue={lastName}
            required
          />
        </div>
        {type === "nino" ? null : (
          <>
            <InputLocality
              ref={inputCityRef}
              name={`visitors[${index}][city]`}
              defaultValue={city || ""}
              required
              host = {host}
            />
            <InputText
              ref={inputEmailRef}
              type="email"
              name={`visitors[${index}][email]`}
              placeholder="Email"
              defaultValue={email}
              required
            />
            <InputText
              ref={inputPhoneRef}
              type="tel"
              name={`visitors[${index}][mobilePhone]`}
              placeholder="Teléfono"
              defaultValue={mobilePhone}
              required
            />
          </>
        )}
      </div>
    </article>
  );
}

function InputRadio({ name, value, label, ...props }) {
  return (
    <label className="cursor-pointer">
      <input
        name={name}
        value={value}
        type="radio"
        className="peer sr-only"
        {...props}
      />
      <span className="border-b-2 border-transparent px-1 pb-0.5 font-display font-medium peer-checked:border-brand-purple peer-disabled:opacity-0">
        {label}
      </span>
    </label>
  );
}

const InputLocality = forwardRef((props, ref) => {  
  const { data: cities, error } = useApi({
    url: props.host + "/api/cities",
  });
  if (!cities) {
    return <InputPlaceholder />;
  }
  return (
    <InputSelect ref={ref} placeholder="Municipio" {...props}>
      {cities.map((city) => (
        <option key={city.id} value={city.id}>
          {city.name}
        </option>
      ))}
      <option value="other">Otro</option>
    </InputSelect>
  );
});
