export default function IconClose(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="currentColor"
      {...props}
    >
      <path d="M23.25,19.297c1.946,2.053,3.769,3.96,5.572,5.886,.5112,.5149,.9732,1.0763,1.38,1.677,.5962,.8455,.4965,1.9975-.236,2.728-.7103,.7477-1.8476,.895-2.725,.353-.6156-.3808-1.1802-.8385-1.68-1.362-1.392-1.409-2.737-2.864-4.111-4.29-.4-.416-.839-.8-1.336-1.266-.5,.561-.956,1.031-1.369,1.532-1.469,1.783-2.9,3.6-4.4,5.355-.5187,.5927-1.1238,1.1038-1.795,1.516-.7694,.4605-1.7537,.3367-2.385-.3-.7422-.6096-.9678-1.6496-.545-2.512,.2887-.5914,.6458-1.1468,1.064-1.655,1.569-1.957,3.17-3.888,4.753-5.834,.41-.5,.793-1.027,1.235-1.6-.346-.416-.617-.778-.924-1.1-1.358-1.442-2.729-2.872-4.088-4.312-.648-.5726-1.0959-1.3376-1.278-2.183-.2091-1.0846,.5007-2.1333,1.5853-2.3424,.3349-.0646,.6808-.0424,1.0047,.0644,.4213,.1766,.8011,.4392,1.115,.771,1.743,1.615,3.464,3.252,5.2,4.875,.1763,.137,.3632,.2597,.559,.367,.2533-.1898,.4926-.3977,.716-.622,1.559-1.793,3.1-3.6,4.655-5.4,.24-.3135,.5041-.6077,.79-.88,.6868-.553,1.6466-.6161,2.4-.158,.8199,.5026,1.1616,1.5205,.811,2.416-.2218,.5498-.5125,1.0693-.865,1.546-1.29,1.754-2.614,3.484-3.929,5.22-.356,.471-.724,.933-1.18,1.52" />
    </svg>
  );
}
