import classNames from "classnames";

export default function Button({ className, children, ...props }) {
  return (
    <button
      className={classNames(
        "h-12 w-full max-w-sm rounded-lg bg-brand-turquoiseLight px-10 font-display font-semibold text-white transition-colors focus:bg-brand-turquoise focus:outline-none active:bg-brand-blue",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
}
