import { format } from "date-fns";
import { es } from "date-fns/locale";

export default function formatDate(
  date,
  formatString = "MMMM yyyy",
  options = {}
) {
  return format(date, formatString, { locale: es, ...options });
}
