import confetti from "canvas-confetti";
import { useEffect } from "react";

import formatCurrency from "../lib/formatCurrency";
import IconBigCongrats from "./icons/bigCongrats";

export default function ReservationConfirmation({response}) {
  useEffect(() => {
    launchConfetti();
  }, []);
  return (
    <section className="grid gap-3 py-16 text-center">
      <IconBigCongrats className="mx-auto h-auto w-16 text-brand-purple" />
      <h2 className="mt-2 font-display text-xl">Folio de tu reservación: {response.playdate_id}</h2>
      <h3 className="mt-2 font-medium text-lg">Precio (a pagar en sucursal): {formatCurrency(response?.price || 0)}</h3> 
      <p>¡Disfruta tu visita!</p>
      <p>
        Si tienes alguna pregunta o necesitas ayuda, no dudes en ponerte en
        contacto con nosotros.
      </p>
      <p>¡Te esperamos!</p>
    </section>
  );
}

// Confetti
const count = 100;

function shot(particleRatio, opts) {
  confetti({
    origin: { y: 0.6 },
    particleCount: Math.floor(count * particleRatio),
    ...opts,
  });
}

function launchConfetti() {
  shot(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  shot(0.2, {
    spread: 60,
  });
  shot(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });
  shot(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });
  shot(0.1, {
    spread: 120,
    startVelocity: 45,
  });
}
