import { AnimatePresence, motion } from "framer-motion";

import { modalBackdrop, modalContainer, springIn } from "../lib/motionVariants";
import IconClose from "./icons/close";

const stars = new URL("../images/stars.svg", import.meta.url);

export default function Modal({ title, hide, hidden, children }) {
  return (
    <AnimatePresence>
      {!hidden && (
        <motion.div
          initial="initial"
          animate="visible"
          exit="hidden"
          className="fixed inset-0 h-full w-full"
        >
          <motion.div
            className="absolute inset-0 bg-brand-cream/95"
            variants={modalBackdrop}
          />
          <motion.div
            variants={modalContainer}
            className="absolute inset-x-0 bottom-0 mx-auto flex h-full max-h-[960px] w-full max-w-xl flex-col justify-end sm:top-0 sm:my-auto sm:justify-center"
          >
            <header className="flex items-center justify-between px-4 pt-5 pb-3 text-right">
              <h2 className="font-display text-2xl md:text-3xl">{title}</h2>
              <AnimatePresence mode="popLayout">
                <motion.button
                  layout="position"
                  transition={{ layout: springIn }}
                  onClick={hide}
                  className="group relative text-white transition-transform duration-200 ease-out-back hover:scale-110 active:scale-90"
                  title="Close"
                >
                  <div className="absolute inset-0 h-full w-full rounded-full bg-brand-pink transition-colors group-hover:bg-brand-red group-active:bg-brand-purple" />
                  <IconClose className="relative" />
                </motion.button>
              </AnimatePresence>
            </header>
            <motion.div
              layout
              transition={{ layout: springIn }}
              className="relative h-full overflow-y-auto overflow-x-hidden rounded-t-xl border border-brand-wheat bg-white bg-contain bg-no-repeat sm:rounded-b-xl"
              style={{
                backgroundImage: `url(${stars})`,
                backgroundPosition: "center bottom",
                backgroundSize: "cover",
              }}
            >
              <motion.div layout="position" className="h-full">
                {children}
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
