import classNames from "classnames";
import { forwardRef } from "react";

const InputText = forwardRef(({ className, children, ...rest }, ref) => {
  return (
    <input
      ref={ref}
      type="text"
      className={classNames(
        "h-11 rounded-lg border-2 border-brand-purple bg-white px-3 transition-colors placeholder:text-brand-deepPurple/50 focus:outline-none focus:ring-2 focus:ring-brand-purple/40",
        className
      )}
      {...rest}
    />
  );
});

export default InputText;
