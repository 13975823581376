import classNames from "classnames";

import { apiPost } from "../lib/api";
import { formObject } from "../lib/formObject";
import useAsyncFn from "../lib/useAsyncFn";
import IconBigCake from "./icons/bigCake";
import IconBigCalendar from "./icons/bigCalendar";
import IconBigInvite from "./icons/bigInvite";
import ReservationConfirmation from "./reservationConfirmation";
import ReservationDate from "./reservationDate";
import ReservationExtras from "./reservationExtras";
import ReservationGuests from "./reservationGuests";
import StepsNavigation from "./stepsNavigation";

const steps = [
  {
    name: "Sucursal y fecha",
    component: ReservationDate,
    icon: IconBigCalendar,
  },
  {
    name: "Invitados",
    component: ReservationGuests,
    icon: IconBigInvite,
  },
  {
    name: "Extras",
    component: ReservationExtras,
    icon: IconBigCake,
  },
];

export default function Reservation({
  hideModal,
  selectedStep,
  setStep,
  setError,
  form,
  updateForm,
  resetForm,
  isAdmin,
  currentError
}) {
  
  const host = "https://admin.playtica.com.mx";
  //const host = "http://playtica-admin.localhost";

  const step = steps[selectedStep];
  const [{ loading, success, error }, send] = useAsyncFn(
    (data) =>      
      apiPost(host+"/api/save_play_date", data)        
        .then((response) => {          
          if(response.error){
            setError(response.error);
          }
          else{
            setError('');
            resetForm();
            return response;
          }
        })
        .catch((error) => {
          alert(error);
          console.error(error);
        }),
    [resetForm]
  );
  console.log('success', success);

  const moveStep = (distance) => {
    setStep(selectedStep + distance);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let data = formObject(event.target);
    let newForm = { ...form, ...data };
    if (selectedStep === steps.length - 1) {      
      send(preparePayload(newForm));
    } else {      
      if(selectedStep == 1 && areChildren(newForm.visitors) === false){
        setError("Debe ingresar los datos de los menores");
      }
      else{
        setError("");
        updateForm(newForm);      
        moveStep(1);
      }
    }
  };
  const handleChange = (event) => {
    // get form that contains this input
    let formNode = event.target.closest("form");
    let data = formObject(formNode);
    updateForm({ ...form, ...data });
  };
  const areChildren = (visitors) => {
    let children = false;    
    for (var i = 0; i < visitors.length; i++) {
      if (visitors[i].type == 'nino') {
        children = true;
        break;
      }
    }
    return children;
  }

  return (
    <div className="px-4 py-5 sm:px-6">      
      <StepsNavigation
        steps={steps}
        setStep={setStep}        
        current={success ? steps.length : selectedStep}
        currentError={currentError}        
      />
      <div
        className={classNames(
          loading ? "pointer-events-none animate-pulse" : ""
        )}
      >
        {success ? (
          <ReservationConfirmation 
            response = {success}            
          />
        ) : (
          <step.component
            form={form}
            updateForm={updateForm}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            step={step}
            moveStep={moveStep}
            hideModal={hideModal}
            isAdmin={isAdmin}
          />
        )}
      </div>
    </div>
  );
}

function preparePayload({ products, ...data }) {
  let payload = {
    // Filter not checked products
    products: products.filter((product) => product.checked),
    ...data,
  };
  return payload;
}
