import classNames from "classnames";

export default function ButtonCircle({
  label,
  small = false,
  colors = "primary", // or "danger" or "warning"
  className,
  children,
  ...props
}) {
  return (
    <button
      className={classNames(
        "group inline-flex items-center gap-3 focus:outline-none",
        className
      )}
      {...props}
    >
      {label && <div className="font-display text-sm font-medium">{label}</div>}
      <div
        className={classNames(
          "relative text-white transition-transform duration-200 ease-out-back group-hover:scale-110 group-active:scale-90",
          small ? "h-8 w-8" : "h-12 w-12"
        )}
      >
        <div
          className={classNames(
            "absolute inset-0 h-full w-full rounded-full transition-colors",
            colors === "primary" &&
              "bg-brand-turquoiseLight group-hover:bg-brand-turquoise group-active:bg-brand-blue",
            colors === "danger" &&
              " bg-brand-pink group-hover:bg-brand-red group-active:bg-brand-purple",
            colors === "warning" &&
              "bg-brand-yellow group-hover:bg-brand-orange group-active:bg-brand-deepOrange"
          )}
        />
        <div className="relative flex h-full w-full items-center justify-center">
          {children}
        </div>
      </div>
    </button>
  );
}
