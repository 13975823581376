import { useState } from "react";

import formatCurrency from "../lib/formatCurrency";
import useApi from "../lib/useApi";
import Button from "./button";
import ButtonBack from "./buttonBack";
import IconOk from "./icons/ok";
import InputCheckbox from "./inputCheckbox";
import InputLabel from "./inputLabel";
import InputSelect from "./inputSelect";
import Intro from "./intro";

export default function ReservationExtras({
  form,
  handleSubmit,
  handleChange,
  moveStep,
  isAdmin,
}) {

  const host = "https://admin.playtica.com.mx";
  //const host = "http://playtica-admin.localhost";

  const { branch, products = [] } = form;
  const { data: allProducts, error } = useApi({
    url: host + "/api/products/by_branch/" + branch,
  });
  const { data: resume } = useApi({
    url: host + "/api/get_price",
    method: "POST",
    body: JSON.stringify(form),
  });

  return (
    <section>
      <Intro title="Extras" className="mb-6">
        <p>
          Por útlimo, te invitamos a escoger entre nuestros productos
          adicionales.
        </p>
      </Intro>
      <form
        onSubmit={handleSubmit}
        onChange={handleChange}
        className="grid gap-5"
      >
        {isAdmin ? (
          <InputLabel label="Método de pago">
            <InputSelect name="paymentMethod" placeholder="Seleccionar">
              <option value="cash">Efectivo</option>
              <option value="card">Tarjeta en sucursal</option>
            </InputSelect>
          </InputLabel>
        ) : null}
        {allProducts
          ? allProducts.map((p, index) => (
              <InputProduct
                key={p.id}
                product={p}
                defaultValue={products.find((x) => x.productId == p.id)}
                index={index}
              />
            ))
          : null}
        <div className="border-deepPurpleLight flex items-center justify-between border-t pt-6">
          <div>Costo de la reserva (a pagar en sucursal)</div>
          <div className="font-display text-lg font-medium">
            {formatCurrency(resume?.price || 0)}
          </div>
        </div>
        <div className="mt-5 flex items-center justify-between gap-5">
          <ButtonBack onClick={() => moveStep(-1)} />
          <Button type="submit">Confirmar reservación</Button>
        </div>
      </form>
    </section>
  );
}

function InputProduct({ product, index, defaultValue = {} }) {
  const quantities = Array.from(Array(20).keys()).map((i) => i + 1);
  // Format price as currency MXN
  let price = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(product?.price/100 || 0);

  return (
    <article className="relative rounded-lg bg-[#F9F8EF] px-4 pt-4 pb-5">
      <input
        type="hidden"
        name={`products[${index}][productId]`}
        value={product?.id}
      />
      <div className="mr-auto grid gap-5">
        <InputLabel
          label={`Agregar ${product?.name}`}
          className="!inline-flex flex-row-reverse items-center justify-end !gap-2.5 !text-xl"
        >
          <InputCheckbox
            name={`products[${index}][checked]`}
            defaultChecked={defaultValue.checked}
          />
        </InputLabel>
        <div className="flex w-full items-center gap-5">
          <img
            src={
              product?.image ||
              "https://images.prismic.io/ntropia-tests/46e1cc38-7676-48ff-a675-af2a1998e8d7_socks.jpg?auto=compress,format"
            }
            width="100"
            height="100"
            className="h-16 w-16 rounded-lg bg-white object-contain"
            loading="lazy"
          />
          <hr className="flex-1 opacity-0" />
          <div className="font-display text-xl">{price}</div>
          <InputSelect
            name={`products[${index}][quantity]`}
            defaultValue={defaultValue.quantity || 1}
            className="w-28"
            required
          >
            {quantities.map((quantity) => (
              <option key={quantity} value={quantity}>
                {quantity}
              </option>
            ))}
          </InputSelect>
        </div>
      </div>
    </article>
  );
}
