export async function apiPost(path, payload) {
  const response = await fetch(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const data = await response.json();
  if (response.ok) return data;
  else {
    console.error(data);
    throw new Error("Error en la petición");
  }
}
