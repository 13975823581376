import { useCallback, useEffect, useState } from "react";

import Modal from "./components/modal";
import Reservation from "./components/reservation";

export default function App({
  globalState,
  externalProps: { isAdmin = false },
}) {

  const [state, setState] = useState(() => globalState.getState());  
  const { hidden, selectedStep = 0, currentError = '', form = {} } = state;
  // console.log("state", state);  

  const hide = useCallback(() => {
    globalState.updateState({ hidden: true });
  }, [globalState]);
  const setStep = useCallback(    
    (newStep) => {      
      globalState.updateState({
        selectedStep: newStep,
      });
    },
    [globalState]
  );
  const setError = useCallback(    
    (newError) => {
      console.log('in function', newError);
      globalState.updateState({
        currentError: newError,
      });
    },
    [globalState]
  );
  const updateForm = useCallback(
    (payload = {}) => {
      globalState.updateState((s) => ({
        form: {
          ...s.form,
          ...payload,
        },
      }));
    },
    [globalState]
  );
  const resetForm = useCallback(() => {
    globalState.updateState({
      selectedStep: 0,
      form: {},
      currentError: ''
    });
  }, [globalState]);

  useEffect(() => {
    globalState.subscribe(setState);    
    return () => {
      globalState.unsubscribe(setState);      
    };
  }, []);

  return (
    <Modal title="Reservar" hidden={hidden} hide={hide}>
      <Reservation
        form={form}
        hideModal={hide}
        selectedStep={selectedStep}
        setStep={setStep}
        setError={setError}
        updateForm={updateForm}
        resetForm={resetForm}
        isAdmin={isAdmin}
        currentError={currentError}
      />
    </Modal>
  );
}
