export default function IconBigCalendar(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="currentColor"
      {...props}
    >
      <path d="M14.1804,27.4349c-.6019-.1166-1.2118-.1873-1.8245-.2114h-.0338c-.4914-.0062-.9153,.3439-1.0021,.8277-.1148,.6008-.1854,1.2092-.2114,1.8202h0c-.0222,.5059,.3334,.9501,.8319,1.0391,.5979,.1072,1.202,.176,1.8086,.2061h.0497c.4753-.0041,.8848-.336,.9873-.8002,.1349-.6072,.2058-1.2268,.2114-1.8488,.025-.5046-.3284-.9494-.8256-1.0391m-.2632,2.6595c-.0222,.0835-.0698,.1237-.1194,.1226-.5673-.0283-1.1322-.0926-1.6913-.1924-.0564-.0087-.0957-.0607-.0888-.1173,.0235-.5641,.0875-1.1258,.1913-1.6807,.0057-.0581,.0574-.1006,.1156-.0948,.002,.0002,.004,.0005,.006,.0008,.5659,.0235,1.1292,.0903,1.6849,.1998,.0582,.0048,.1014,.0559,.0966,.1141-.0001,.0015-.0003,.0029-.0004,.0043-.0052,.5559-.0672,1.1099-.185,1.6532" />
      <path d="M14.7724,22.361c-.602-.1163-1.2119-.1869-1.8245-.2114-.5023-.0177-.9423,.3339-1.0359,.8277-.1147,.6008-.1854,1.2092-.2114,1.8202-.0227,.506,.3332,.9505,.8319,1.0391,.5979,.107,1.202,.1755,1.8086,.2051h.0507c.4749-.0044,.8841-.3356,.9873-.7991,.1349-.6072,.2058-1.2268,.2114-1.8488,.0257-.5041-.3268-.9489-.8234-1.0391" />
      <path d="M23.0248,20.6814c-.0221,.5056,.3336,.9495,.8319,1.038,.5978,.1076,1.2019,.1761,1.8086,.2051h.0497c.4752-.0045,.8845-.3362,.9873-.8002,.1351-.6071,.206-1.2268,.2114-1.8488,.025-.5044-.3285-.9488-.8256-1.038-.6015-.1168-1.2111-.1875-1.8234-.2114-.5024-.0173-.9425,.334-1.037,.8277-.1147,.6011-.1854,1.2099-.2114,1.8213m1.0941-1.6511c.0061-.0577,.0575-.0996,.1152-.0941h.0063c.5631,.021,1.1239,.0842,1.6775,.1892,.0582,.0047,.1015,.0557,.0968,.1139-.0002,.0018-.0004,.0037-.0006,.0055-.0051,.5559-.0671,1.1099-.185,1.6532-.0222,.0835-.0613,.1184-.1194,.1216-.5674-.0271-1.1323-.0914-1.6913-.1924-.0564-.0087-.0957-.0607-.0888-.1173,.023-.5641,.0869-1.1259,.1913-1.6807" />
      <path d="M36.5741,7.1754c-1.3129-.2389-2.7388-.4767-4.2367-.7093l.0349-.8224c.0427-1.0331-.727-1.9205-1.7558-2.0243l-1.1141-.112c-1.0742-.1064-2.0312,.6782-2.1376,1.7524-.0054,.0545-.0085,.1093-.0093,.164h0v.3287c-2.2695-.3023-4.6574-.5909-7.115-.8615l.0402-.9577c.0444-1.0335-.7254-1.922-1.7547-2.0253l-1.1141-.112c-1.0742-.1064-2.0312,.6782-2.1376,1.7524-.0054,.0545-.0085,.1093-.0093,.164h0c0,.2252,0,.4461-.0053,.667-1.5412-.1469-3.0454-.278-4.4703-.3911-1.4875-.1322-2.8361,.8762-3.1299,2.3403C5.4925,11.7334,1.3065,24.8641,1,29.5088c.0007,1.3661,.7542,2.6207,1.9598,3.2631,2.9217,2.0274,3.575,2.1363,3.8213,2.1765h.0444c.1132,.0311,.2283,.0548,.3446,.0708,8.6509,1.4989,17.5682,2.6004,25.7921,3.186,.0782,.0053,.1554,.0074,.2336,.0074,1.4562,.0385,2.7096-1.0216,2.9132-2.464,.6617-5.0242,2.464-20.806,2.8784-25.4179,.1408-1.5214-.9094-2.8963-2.4143-3.1606m-7.8613-1.7304c.0037-.3304,.2746-.5952,.605-.5915,.0168,.0002,.0336,.0011,.0503,.0027l1.1131,.112c.313,.0321,.5471,.3019,.5349,.6163l-.1385,3.2547c-.0146,.3178-.2759,.5684-.5941,.5698h-.0116l-.9302-.019c-.3135-.0064-.5681-.2554-.5814-.5687-.0497-1.204-.0645-2.2462-.0455-3.3826m-12.0938-1.6987c.0037-.3304,.2746-.5952,.605-.5915,.0168,.0002,.0336,.0011,.0503,.0027l1.1173,.112c.3138,.0322,.5483,.3032,.5349,.6184l-.1427,3.2547c-.018,.3211-.2851,.5715-.6068,.5687l-.9302-.019c-.3133-.0069-.5675-.2556-.5814-.5687-.0497-1.2114-.0645-2.2536-.0455-3.3773M3.7388,31.6546c-1.037-.7199-1.4397-1.316-1.39-2.057,.2114-3.1712,2.409-10.7756,4.4069-16.7554-.8308,6.7651-1.797,15.9615-2.001,19.0333-.0112,.1569-.0112,.3145,0,.4714-.3013-.1987-.6342-.4228-1.0211-.6913m31.0309,3.9206c-.1349,.8137-.8861,1.3772-1.705,1.279-8.1753-.5814-17.0461-1.6775-25.6801-3.1712-.812-.1424-1.3698-.8964-1.2685-1.7145,.1987-2.9735,1.1131-11.7333,1.9249-18.4044,5.1257,.3827,10.319,.9017,15.3421,1.4027l13.636,1.3604c-.7135,6.5643-1.7706,15.6592-2.2431,19.25" />
      <path d="M29.1768,22.2352c.5977,.1078,1.2019,.1763,1.8086,.2051h.0507c.4751-.0049,.8841-.3364,.9873-.8002,.1349-.6072,.2057-1.2268,.2114-1.8488,.025-.5044-.3285-.9488-.8256-1.038-.602-.1159-1.2119-.1865-1.8245-.2114-.5023-.0177-.9423,.3339-1.0359,.8277-.1144,.6012-.185,1.2099-.2114,1.8213-.0221,.5056,.3336,.9495,.8319,1.038m.2622-2.6881c.0127-.0645,.0624-.1057,.1205-.0941,.5632,.021,1.124,.085,1.6775,.1913,.0582,.0047,.1015,.0557,.0968,.1139-.0002,.0018-.0003,.0037-.0006,.0055-.0051,.5556-.0671,1.1092-.185,1.6522-.0222,.0835-.0571,.1163-.1194,.1216-.5674-.0272-1.1323-.0914-1.6913-.1924-.0569-.0082-.0968-.0603-.0898-.1173,.0234-.5641,.0873-1.1258,.1913-1.6807" />
      <path d="M25.66,23.6908c-.6016-.1166-1.2111-.1872-1.8234-.2114-.5009-.0146-.939,.335-1.0359,.8266-.1151,.6011-.1857,1.2098-.2114,1.8213-.0221,.5056,.3336,.9495,.8319,1.038,.5979,.107,1.202,.1755,1.8086,.2051h.0497c.4752-.0045,.8845-.3362,.9873-.8002,.1348-.6072,.2057-1.2268,.2114-1.8488,.024-.5041-.329-.948-.8256-1.038m-.2632,2.6585c-.0222,.0846-.0592,.1226-.1194,.1226-.5673-.0277-1.1322-.092-1.6913-.1924-.0569-.0087-.0964-.0613-.0888-.1184,.0229-.5641,.0869-1.1259,.1913-1.6807,.0061-.0572,.0568-.099,.1142-.0941h.0063c.5628,.021,1.1233,.0846,1.6765,.1903,.0582,.0047,.1015,.0557,.0968,.1139-.0002,.0018-.0004,.0037-.0006,.0055-.0054,.5556-.0674,1.1092-.185,1.6522" />
      <path d="M20.5037,17.544c-.6019-.1166-1.2118-.1873-1.8245-.2114h-.0338c-.4914-.0062-.9153,.3439-1.0021,.8277-.1147,.6011-.1853,1.2099-.2114,1.8213-.0217,.5057,.3337,.9496,.8319,1.0391,.5979,.1064,1.202,.1749,1.8086,.2051h.0497c.4753-.0041,.8848-.336,.9873-.8002,.1351-.6071,.206-1.2268,.2114-1.8488,.025-.504-.3279-.9483-.8245-1.038m-.2643,2.6585c-.0082,.0629-.0568,.1128-.1194,.1226-.5673-.0277-1.1322-.092-1.6913-.1924-.0564-.0087-.0957-.0607-.0888-.1173,.0233-.5641,.0872-1.1258,.1913-1.6807,.0061-.0577,.0575-.0996,.1152-.0941h.0063c.5632,.0213,1.124,.0853,1.6775,.1913,.0581,.0053,.101,.0567,.0957,.1149-.0001,.0015-.0003,.003-.0005,.0046-.0049,.5556-.0668,1.1092-.185,1.6522" />
      <path d="M25.066,28.7646c-.6019-.1167-1.2118-.1874-1.8245-.2114-.5025-.0178-.9429,.3337-1.037,.8277-.1129,.6013-.1818,1.21-.2061,1.8213-.0222,.5059,.3334,.9501,.8319,1.0391,.5979,.1067,1.202,.1752,1.8086,.2051h.0497c.4753-.0041,.8848-.336,.9873-.8002,.1345-.6072,.2054-1.2269,.2114-1.8488,.0245-.5042-.3288-.9484-.8256-1.038m-.2632,2.6585c-.0222,.0835-.0581,.1279-.1194,.1226-.5673-.0277-1.1322-.092-1.6913-.1924-.0564-.0087-.0957-.0607-.0888-.1173,.0233-.5641,.0872-1.1258,.1913-1.6807,.0061-.0577,.0575-.0996,.1152-.0941h.0063c.5632,.0213,1.124,.0852,1.6775,.1913,.0582,.0047,.1015,.0557,.0968,.1139-.0002,.0018-.0004,.0037-.0006,.0055-.0054,.5556-.0674,1.1092-.185,1.6522" />
      <path d="M28.7403,27.6875c.5979,.1065,1.202,.1746,1.8086,.204h.0507c.4752-.0045,.8845-.3362,.9873-.8002,.1348-.6072,.2056-1.2268,.2114-1.8488,.0223-.5032-.3309-.9454-.8266-1.0349-.602-.1163-1.2119-.1869-1.8245-.2114-.5022-.0178-.9424,.3331-1.037,.8266-.1146,.6008-.1853,1.2092-.2114,1.8202-.0227,.506,.3332,.9505,.8319,1.0391m.0708-1.0084c.023-.5638,.087-1.1251,.1913-1.6797,.0061-.0577,.0575-.0996,.1152-.0941h.0063c.5629,.0204,1.1234,.084,1.6765,.1903,.0582,.0048,.1015,.0559,.0966,.114-.0001,.0015-.0003,.0029-.0005,.0043-.0051,.5559-.0671,1.1099-.185,1.6532-.0222,.0835-.0645,.1247-.1194,.1226-.5673-.0282-1.1322-.0925-1.6913-.1924-.0564-.0087-.0957-.0607-.0888-.1173l-.0011-.0011Z" />
      <path d="M20.0661,22.9953c-.6019-.1164-1.2119-.1871-1.8245-.2114-.503-.0191-.9441,.333-1.037,.8277-.113,.6013-.1819,1.21-.2061,1.8213-.0221,.5056,.3336,.9495,.8319,1.038,.5978,.1073,1.202,.1758,1.8086,.2051h.0497c.4753-.0041,.8848-.336,.9873-.8002,.1349-.6072,.2057-1.2268,.2114-1.8488,.0245-.5042-.3288-.9484-.8256-1.038m-.2632,2.6585c-.0222,.0846-.0613,.1173-.1194,.1226-.5673-.0277-1.1322-.092-1.6913-.1924-.0569-.0087-.0964-.0613-.0888-.1184,.0233-.5638,.0872-1.1251,.1913-1.6797,.0056-.0581,.0571-.1007,.1152-.0951h.0063c.5632,.021,1.1241,.0849,1.6775,.1913,.0582,.0047,.1015,.0557,.0968,.1139-.0002,.0018-.0004,.0037-.0006,.0055-.0054,.5556-.0674,1.1092-.185,1.6522" />
      <path d="M19.4763,28.0691c-.6019-.1165-1.2118-.1872-1.8245-.2114-.5021-.0182-.9422,.333-1.0359,.8266-.1149,.6011-.1856,1.2098-.2114,1.8213-.0222,.5059,.3334,.9501,.8319,1.0391,.5979,.1067,1.202,.1752,1.8086,.2051h.0518c.4753-.0041,.8848-.336,.9873-.8002,.1345-.6072,.2054-1.2269,.2114-1.8488,.0245-.5042-.3288-.9484-.8256-1.038m-.2632,2.6585c-.0222,.0835-.0655,.1163-.1194,.1226-.5673-.0278-1.1322-.092-1.6913-.1924-.0564-.0087-.0957-.0607-.0888-.1173,.023-.5641,.087-1.1258,.1913-1.6807,.0058-.0581,.0576-.1005,.1157-.0947,.0016,.0002,.0032,.0004,.0048,.0006,.5632,.0208,1.124,.0844,1.6775,.1903,.0582,.0047,.1015,.0557,.0968,.1139-.0002,.0018-.0004,.0037-.0006,.0055-.0054,.5556-.0674,1.1092-.185,1.6522" />
    </svg>
  );
}
