import { parse } from "date-fns";
import { addDays } from "date-fns/esm";
import { useMemo, useRef, useState } from "react";

import useApi from "../lib/useApi";
import Button from "./button";
import InputDate from "./inputDate";
import InputLabel from "./inputLabel";
import InputPlaceholder from "./inputPlaceholder";
import InputSelect from "./inputSelect";
import Intro from "./intro";

const tomorrow = addDays(new Date(), 1);
const formatHour = (date) => {
  // Format date to "HH:MM" with am or pm
  return date.toLocaleTimeString("es-MX", {
    hour: "2-digit",
    minute: "2-digit",
  });
};
const generateHoursRange = (start, end) => {
  let result = [];
  // Generate hours array in 30 minute intervals from start to end
  for (let i = start; i < end; i++) {
    result.push(formatHour(new Date(2021, 0, 1, i, 0)));
    // Except last hour
    if (i < end - 1) result.push(formatHour(new Date(2021, 0, 1, i, 30)));
  }
  return result;
};

const hours = [
  [11, 20], // Sunday
  [12, 20], // Monday
  [12, 20], // Tuesday
  [12, 20], // Wednesday
  [12, 20], // Thursday
  [11, 20], // Friday
  [11, 20], // Saturday
];

export default function ReservationDate({
  form: { branch, branchName, date, start, end },
  step,
  handleSubmit,
  handleChange,
}) {

  const host = "https://admin.playtica.com.mx";
  //const host = "http://playtica-admin.localhost";

  const [startHours, endHours] = useMemo(() => {
    if (!date) return [[], []];
    let dateObj = parse(date, "yyyy-MM-dd", new Date());
    let [first, last] = hours[dateObj.getDay()];
    // parse start string to number
    let startHour = start ? parseInt(start.split(":")[0]) + 1 : first;
    return [
      generateHoursRange(first, last),
      generateHoursRange(startHour, last + 1),
    ];
  }, [date, start]);

  return (
    <section>
      <Intro title="Sucursal y fecha" className="mb-6">
        <p>Elige la sucursal y la hora en la que te gustaría visitarnos.</p>
      </Intro>
      <form
        onSubmit={handleSubmit}
        onChange={handleChange}
        className="grid gap-5"
      >
        <InputLabel label="Sucursal">
          <InputBranches
            name="branch"
            defaultValue={branch || ""}
            branchName={branchName}
            host = {host}
            required
          />
        </InputLabel>
        <InputLabel label="Fecha">
          <InputDate
            name="date"
            fromDate={tomorrow}
            defaultValue={date}
            onChange={handleChange}
            required
          />
        </InputLabel>
        <div className="grid grid-cols-2 gap-4">
          <InputLabel label="Hora de entrada">
            <InputSelect
              name="start"
              placeholder="Selecciona"
              defaultValue={start || ""}
              disabled={!date}
              required
            >
              {startHours.map((hour) => (
                <option key={hour}>{hour}</option>
              ))}
            </InputSelect>
          </InputLabel>
          <InputLabel label="Duración de la visita">
            <InputSelect
              name="hours"
              placeholder="Selecciona"
              defaultValue={end || ""}
              disabled={!date || !start}
              required
            >
              <option value="1">1 hora</option>
              <option value="2">2 horas</option>
              <option value="0">Todo el día</option>
            </InputSelect>
          </InputLabel>
        </div>
        <div className="mt-5 flex justify-center gap-5">
          <Button type="submit">Continuar</Button>
        </div>
      </form>
    </section>
  );
}

function InputBranches({ branchName, host, ...props }) {
  const { data: branches, error } = useApi({
    url: host + "/api/branches",
  });
  const hiddenInputRef = useRef();

  const handleChangeInternal = (event) => {
    let selectedBranch = branches.find(
      (branch) => branch.id == event.target.value
    );
    hiddenInputRef.current.value = selectedBranch?.name;
  };

  if (!branches) {
    return <InputPlaceholder />;
  }
  return (
    <>
      <input
        ref={hiddenInputRef}
        type="hidden"
        name="branchName"
        defaultValue={branchName}
        readOnly
      />
      <InputSelect
        name="branch"
        placeholder={
          branches ? "Selecciona una sucursal" : error ? "Error" : "Cargando..."
        }
        onChange={handleChangeInternal}
        {...props}
      >
        {branches &&
          branches.map((branch) => (
            <option key={branch.id} value={branch.id}>
              {branch.name}
            </option>
          ))}
      </InputSelect>
    </>
  );
}
