import IconChevronLeft from "./icons/chevronLeft";

export default function (props) {
  return (
    <button
      type="button"
      className="flex h-12 items-center gap-3 rounded-lg px-3 opacity-40 active:opacity-100"
      {...props}
    >
      <IconChevronLeft />
      <span className="font-display font-semibold">Volver</span>
    </button>
  );
}
