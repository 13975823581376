import classNames from "classnames";

import IconOk from "./icons/ok";

export default function InputCheckbox({ className, ...props }) {
  return (
    <div className="relative">
      <input
        type="checkbox"
        className="peer absolute inset-0 h-full w-full cursor-pointer opacity-0"
        {...props}
      />
      <span className="flex h-6 w-6 items-center rounded-md border-[3px] border-brand-purple bg-white text-transparent transition-colors peer-checked:bg-brand-purple peer-checked:text-white peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-brand-purple/40">
        <IconOk />
      </span>
    </div>
  );
}
