import { useCallback, useRef, useState } from "react";

import useMountedState from "./useMountedState";

export default function useAsyncFn(
  fn,
  deps,
  initialState = { loading: false }
) {
  const lastCallId = useRef(0);
  const isMounted = useMountedState();
  const [state, set] = useState(initialState);
  const dispatch = useCallback((...args) => {
    const callId = ++lastCallId.current;
    if (!state.loading) {
      set((prevState) => ({ ...prevState, loading: true }));
    }
    return fn(...args).then(
      (success) => {
        isMounted() &&
          callId === lastCallId.current &&
          set({ success, loading: false });
        return success;
      },
      (error) => {
        console.error(error);
        isMounted() &&
          callId === lastCallId.current &&
          set({ error, loading: false });
        return error;
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return [state, dispatch];
}
